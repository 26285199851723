import React, { useContext } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { CursorContext, CURSOR_TYPES } from "context/CursorContext";

export default function NodeRealisationTeaser({ node }) {
  const router = useRouter();
  const { startHover, setHover } = useContext(CursorContext);
  const hasBaseline = node.field_baseline || false;
  const isCaseStudy = node.field_case_study || false;

  return (
    <article
      className="group relative pb-[94.2%] rounded-3xl overflow-hidden cursor-none scale-radius"
      onClick={() => router.push(node.path.alias)}
      onMouseOver={() =>
        startHover(
          isCaseStudy ? CURSOR_TYPES.CASE_STUDY : CURSOR_TYPES.REALIZATION
        )
      }
      onMouseLeave={() => setHover(false)}
      onFocus={() => null}
    >
      <div className="absolute inset-0 bg-dark scale-100 group-hover:scale-105 transition-transform ease-in-sine duration-300">
        <Image
          src={node.field_image.image_style_uri.large_square}
          alt={node.field_image.resourceIdObjMeta.alt}
          className="opacity-100 group-hover:opacity-75 transition-opacity ease-in-sine duration-300 rounded-3xl"
          fill
        />
      </div>
      <div
        className="absolute inset-0 bg-gradient-to-t from-dark via-transparent"
        aria-hidden="true"
      />
      {isCaseStudy && (
        <span className="absolute top-0 right-0 inline-block m-8 py-4 px-7 border-2 rounded-full text-base font-medium font-important tracking-wider leading-none whitespace-nowrap bg-primary border-primary text-dark">
          Étude de cas
        </span>
      )}
      <div className="absolute bottom-0 inset-x-0 p-8">
        <h3 className="font-important tracking-wider text-3xl 2xl:text-4xl font-semibold text-light">
          {node.title}
        </h3>

        {hasBaseline && (
          <div className="prose prose-light mt-2">{node.field_baseline}</div>
        )}

        <div className="sr-only">
          <Link href={node.path.alias} prefetch={false}>
            <span className="sr-only">En savoir plus</span>
          </Link>
        </div>
      </div>
    </article>
  );
}
