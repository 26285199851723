import React from "react";
import Body from "components/Body";
import MoreLink from "components/MoreLink";

export default function RealisationCTABlock({ block, showLink = false }) {
  return (
    <>
      <div className="relative rounded-3xl bg-primary text-dark overflow-hidden p-8">
        <h2 className="text-3xl 2xl:text-4xl font-bold mb-8 2xl:mb-12">
          {block.field_title}
        </h2>
        {block.body && (
          <div className="prose prose-dark">
            <Body value={block.body.processed} />
          </div>
        )}
      </div>
      {showLink && (
        <div className="lg:text-right mt-12">
          <MoreLink href="/realisations">Toutes nos réalisations</MoreLink>
        </div>
      )}
    </>
  );
}
