import React, { isValidElement } from "react";
import Scribbles4 from "components/SVG/Scribble/Scribbles4";
import NodeRealisationTeaser from "components/Realisation/NodeRealisationTeaser";
import classNames from "lib/classNames";
import { m } from "framer-motion";
import { fadeUp } from "lib/variants";

export default function NodeRealisationList({ nodes }) {
  const showScribble = nodes.length >= 2;
  const even = nodes.filter((node, index) => index % 2 === 0);
  const odd = nodes.filter((node, index) => index % 2 !== 0);

  return (
    <div
      className={classNames(
        "relative grid grid-cols-1 lg:grid-cols-2 gap-8 2xl:gap-12",
        showScribble && "mt-20 lg:mt-0"
      )}
    >
      {showScribble && (
        <Scribbles4 className="absolute top-0 right-1/2 lg:right-1/4 translate-x-1/2 -translate-y-20 lg:translate-y-0 text-primary w-4/5 md:w-2/3 lg:w-[24.8vw]" />
      )}

      <div className="flex lg:hidden flex-col gap-8 2xl:gap-12">
        {nodes.map((node) => {
          return (
            <m.div
              key={node.id}
              variants={fadeUp}
              initial="out"
              whileInView="enter"
              className="will-change-transform"
            >
              {isValidElement(node) ? (
                node
              ) : (
                <NodeRealisationTeaser node={node} />
              )}
            </m.div>
          );
        })}
      </div>

      <div className="hidden lg:flex flex-col gap-8 2xl:gap-12">
        {even.map((node) => {
          const key = `even-${node.id}`;

          return (
            <m.div
              key={key}
              variants={fadeUp}
              initial="out"
              whileInView="enter"
              className="will-change-transform"
            >
              {isValidElement(node) ? (
                node
              ) : (
                <NodeRealisationTeaser node={node} />
              )}
            </m.div>
          );
        })}
      </div>

      <div className="hidden lg:flex flex-col gap-8 2xl:gap-12 lg:mt-20">
        {odd.map((node) => {
          const key = `odd-${node.id}`;
          return (
            <m.div
              key={key}
              variants={fadeUp}
              initial="out"
              whileInView="enter"
              classNames="will-change-transform"
            >
              {isValidElement(node) ? (
                node
              ) : (
                <NodeRealisationTeaser node={node} />
              )}
            </m.div>
          );
        })}
      </div>
    </div>
  );
}
